import { BaseDto } from "@/shared/dtos/base-dto";

export class categoria_invetario extends BaseDto {
  public nombre!: string;
  public id_padre!: number;
  public padre!: categoria_invetario;
  public items!: categoria_invetario[];
  public concat_data!: string;
  public text!: string;
  public id_inventario!:number;
}
